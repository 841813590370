import { GetStaticProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { SEO } from '@/components';
import ErrorContainer from '@/containers/Error/ErrorPage';
import HomeLayout from '@/layouts/home.layout';

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      // @ts-expect-error wontfix
      ...(await serverSideTranslations(locale, [
        'landing',
        'common',
        'footer',
        'nav'
      ])),
      locale,
      reloadOnPrerender: process.env.NODE_ENV !== 'production'
    }
  };
};

const ErrorPage = () => (
  <HomeLayout>
    <SEO title="404" />
    <ErrorContainer />
  </HomeLayout>
);

export default ErrorPage;
